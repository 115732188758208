@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-pexel {
  background-color: #000000;
  background-image: url("../src/assets/images/pexels-expect-best.png");
  background-size: cover;
  overflow: hidden;
  position: relative;
}

.h-screen-50 {
  height: 50vh;
}

.bg-pexel .bg-pexel-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(
    84.23deg,
    rgba(23, 30, 9, 0.95) 17.33%,
    rgba(23, 30, 9, 0.4) 96.44%
  );
}

.bg-gradient-to-t {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 38.54%
  );
}

/* Custom Animation */

@keyframes float-up-down {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes float-left-right {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
}

.animate-float-up-down {
  animation: float-up-down 3s ease-in-out infinite;
}

.animate-float-left-right {
  animation: float-left-right 3s ease-in-out infinite;
}

.vector-highlighter {
  background-image: url("https://cdn.fivenapp.com/Logo/vector-highlighter.svg");
  background-position: center center;
  background-repeat: no-repeat;
}

.no-outline:focus {
  outline: none !important;
}

.dot-loading-animation {
  position: relative;
}

.dot-loading-animation::after {
  content: " .";
  animation: loading 1s infinite;
}

@keyframes loading {
  0% {
    content: " .";
  }
  33% {
    content: " ..";
  }
  66% {
    content: " ...";
  }
}
